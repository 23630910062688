<template>
  <div>
    <!-- {{ this.dataStudent }} -->
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3"
      @on-complete="formSubmitted"
    >
      <!-- accoint details tab -->
      <tab-content
        title="Data Siswa"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Data Pribadi
              </h5>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="NISN"
                label-for="nisn"
              >
                <validation-provider
                  #default="{ errors }"
                  name="NISN"
                  rules="required"
                >
                  <b-form-input
                    id="nisn"
                    v-model="dataStudent.nisn"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="9976404XXX"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nama Lengkap"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Lengkap"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="dataStudent.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Jhon Doe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Tempat Lahir"
                label-for="birth_place"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tempat Lahir"
                  rules="required"
                >
                  <b-form-input
                    id="birth_place"
                    v-model="dataStudent.birth_place"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Yogyakarta"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Tanggal Lahir"
                label-for="birth_date"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tanggal Lahir"
                  rules="required"
                >
                  <b-form-input
                    id="birth_date"
                    v-model="dataStudent.birth_date"
                    type="date"
                    :state="errors.length > 0 ? false : null"
                    placeholder="21-05-1992"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Jenis Kelamin"
                rules="required"
              >
                <b-form-group
                  label="Jenis Kelamin"
                  label-for="gender"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="gender"
                    v-model="dataStudent.gender"
                    :reduce="(gender) => gender.value"
                    placeholder="Pilih Jenis Kelamin"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataGender"
                    :selectable="
                      (option) => !option.value.includes('select_value')
                    "
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Agama"
                rules="required"
              >
                <b-form-group
                  label="Agama"
                  label-for="religion"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="religion"
                    v-model="dataStudent.religion"
                    :reduce="(religion) => religion.value"
                    placeholder="Pilih Agama"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataReligion"
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Kewarganegaraan"
                rules="required"
              >
                <b-form-group
                  label="Kewarganegaraan"
                  label-for="nationality"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="nationality"
                    v-model="dataStudent.nationality"
                    :reduce="(nationality) => nationality.name"
                    placeholder="Pilih Kewarganegaraan"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataCountries"
                    label="name"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Anak ke (dikeluarga)"
                label-for="child_number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Anak ke (dikeluarga)"
                  rules="required"
                >
                  <b-form-input
                    id="child_number"
                    v-model="dataStudent.child_number"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Jumlah Sudara Kandung"
                label-for="number_of_sibling"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Jumlah Sudara Kandung"
                  rules="required"
                >
                  <b-form-input
                    id="number_of_sibling"
                    v-model="dataStudent.number_of_sibling"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Hubungan dalam Keluarga"
                label-for="relationship_in_family"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Hubungan dalam Keluarga"
                  rules="required"
                >
                  <b-form-input
                    id="relationship_in_family"
                    v-model="dataStudent.relationship_in_family"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Anak"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Bahasa Sehari-hari"
                label-for="every_day_language"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Bahasa Sehari-hari"
                  rules="required"
                >
                  <b-form-input
                    id="every_day_language"
                    v-model="dataStudent.every_day_language"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Bahasa Indonesia"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Provinsi"
                rules="required"
              >
                <b-form-group
                  label="Provinsi"
                  label-for="province"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="province"
                    v-model="locations.province"
                    placeholder="Pilih Provinsi"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataProvinces"
                    label="name"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Kabupaten/Kota"
                rules="required"
              >
                <b-form-group
                  label="Kabupaten/Kota"
                  label-for="city"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="city"
                    v-model="locations.city"
                    placeholder="Pilih Kabupaten/Kota"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="filterCities"
                    label="name"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Kecamatan"
                rules="required"
              >
                <b-form-group
                  label="Kecamatan"
                  label-for="distric"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="distric"
                    v-model="locations.distric"
                    placeholder="Pilih Kecamatan"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="filterDistrics"
                    label="name"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Kelurahan/Desa"
                rules="required"
              >
                <b-form-group
                  label="Kelurahan/Desa"
                  label-for="subdistric"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="subdistric"
                    v-model="locations.subdistric"
                    placeholder="Pilih Kelurahan/Desa"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="filterSubDistrics"
                    label="name"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Alamat"
                label-for="address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Alamat"
                  rules="required"
                >
                  <b-form-input
                    id="address"
                    v-model="dataStudent.address"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Jl. Kaliurang Km. 7 No.B5"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Jarak Ke Sekolah"
                label-for="distance"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Jarak Ke Sekolah"
                  rules="required"
                >
                  <b-form-input
                    id="distance"
                    v-model="dataStudent.distance"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="14"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Anak ke (1 Lembaga)"
                label-for="child_number_institution"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Anak ke (1 Lembaga)"
                  rules="required"
                >
                  <b-form-input
                    id="child_number_institution"
                    v-model="dataStudent.child_number_institution"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="dataStudent.religion === 1"
              md="6"
            >
              <validation-provider
                #default="{ errors }"
                name="Kemampuan Membaca Al-Qur'an"
                rules="required"
              >
                <b-form-group
                  label="Kemampuan Membaca Al-Qur'an"
                  label-for="reading_ability_quran"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="reading_ability_quran"
                    v-model="dataStudent.reading_ability_quran"
                    :reduce="(read) => read.value"
                    placeholder="Pilih Kemampuan Membaca Al-Qur'an"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataReadingQuran"
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Golongan Darah"
                label-for="blood_type"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Golongan Darah"
                  rules="required"
                >
                  <b-form-input
                    id="blood_type"
                    v-model="dataStudent.blood_type"
                    :state="errors.length > 0 ? false : null"
                    placeholder="O"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Riwayat Penyakit"
                label-for="illness_history"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Riwayat Penyakit"
                  rules="required"
                >
                  <b-form-input
                    id="illness_history"
                    v-model="dataStudent.illness_history"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Ada"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- personal details tab -->
      <tab-content
        title="Data Orang Tua/Wali"
        :before-change="validationFormInfo"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="6"
              class="mb-2"
            >
              <h5 class="mb-0">
                Data Ayah
              </h5>
            </b-col>
            <b-col
              cols="6"
              class="mb-2"
            >
              <h5 class="mb-0">
                Data Ibu
              </h5>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nama Ayah/Wali"
                label-for="father_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Ayah/Wali"
                  rules="required"
                >
                  <b-form-input
                    id="father_name"
                    v-model="dataStudent.father_name"
                    placeholder="John Doe"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nama Ibu/Wali"
                label-for="mother_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama Ibu/Wali"
                  rules="required"
                >
                  <b-form-input
                    id="mother_name"
                    v-model="dataStudent.mother_name"
                    placeholder="John Doe"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Tempat Lahir"
                label-for="father_birth_place"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tempat Lahir"
                  rules="required"
                >
                  <b-form-input
                    id="father_birth_place"
                    v-model="dataStudent.father_birth_place"
                    placeholder="Yogyakarta"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Tempat Lahir"
                label-for="mother_birth_place"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tempat Lahir"
                  rules="required"
                >
                  <b-form-input
                    id="mother_birth_place"
                    v-model="dataStudent.mother_birth_place"
                    placeholder="Yogyakarta"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Tanggal Lahir"
                label-for="father_birth_date"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tanggal Lahir"
                  rules="required"
                >
                  <b-form-input
                    id="father_birth_date"
                    v-model="dataStudent.father_birth_date"
                    placeholder="Yogyakarta"
                    :state="errors.length > 0 ? false : null"
                    type="date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Tanggal Lahir"
                label-for="mother_birth_date"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tanggal Lahir"
                  rules="required"
                >
                  <b-form-input
                    id="mother_birth_date"
                    v-model="dataStudent.mother_birth_date"
                    placeholder="Yogyakarta"
                    :state="errors.length > 0 ? false : null"
                    type="date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Agama"
                rules="required"
              >
                <b-form-group
                  label="Agama"
                  label-for="father_religion"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="father_religion"
                    v-model="dataStudent.father_religion"
                    :reduce="(f_religion) => f_religion.value"
                    placeholder="Pilih Agama"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataReligion"
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Agama"
                rules="required"
              >
                <b-form-group
                  label="Agama"
                  label-for="mother_religion"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="mother_religion"
                    v-model="dataStudent.mother_religion"
                    :reduce="(m_religion) => m_religion.value"
                    placeholder="Pilih Agama"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataReligion"
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Kewarganegaraan"
                rules="required"
              >
                <b-form-group
                  label="Kewarganegaraan"
                  label-for="father_nationality"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="father_nationality"
                    v-model="dataStudent.father_nationality"
                    :reduce="(f_nationality) => f_nationality.name"
                    placeholder="Pilih Kewarganegaraan"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataCountries"
                    label="name"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Kewarganegaraan"
                rules="required"
              >
                <b-form-group
                  label="Kewarganegaraan"
                  label-for="mother_nationality"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="mother_nationality"
                    v-model="dataStudent.mother_nationality"
                    :reduce="(m_nationality) => m_nationality.name"
                    placeholder="Pilih Kewarganegaraan"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataCountries"
                    label="name"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Pendidikan Terakhir"
                rules="required"
              >
                <b-form-group
                  label="Pendidikan Terakhir"
                  label-for="father_education_level_id"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="father_education_level_id"
                    v-model="dataStudent.father_education_level_id"
                    :reduce="(f_education) => f_education.value"
                    placeholder="Pilih Pendidikan Terakhir"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataEducation"
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Pendidikan Terakhir"
                rules="required"
              >
                <b-form-group
                  label="Pendidikan Terakhir"
                  label-for="mother_education_level_id"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="mother_education_level_id"
                    v-model="dataStudent.mother_education_level_id"
                    :reduce="(m_education) => m_education.value"
                    placeholder="Pilih Pendidikan Terakhir"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataEducation"
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Jurusan"
                label-for="father_education_major"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Jurusan"
                  rules="required"
                >
                  <b-form-input
                    id="father_education_major"
                    v-model="dataStudent.father_education_major"
                    placeholder="Teknik Komputer dan Jaringan"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Jurusan"
                label-for="mother_education_major"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Jurusan"
                  rules="required"
                >
                  <b-form-input
                    id="mother_education_major"
                    v-model="dataStudent.mother_education_major"
                    placeholder="Teknik Komputer dan Jaringan"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Pendapatan/Bulan"
                label-for="father_salary"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Pendapatan/Bulan"
                  rules="required"
                >
                  <b-form-input
                    id="father_salary"
                    v-model="dataStudent.father_salary"
                    placeholder="2500000"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Pendapatan/Bulan"
                label-for="mother_salary"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Pendapatan/Bulan"
                  rules="required"
                >
                  <b-form-input
                    id="mother_salary"
                    v-model="dataStudent.mother_salary"
                    placeholder="2500000"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Pekerjaan"
                label-for="father_profession"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Pekerjaan"
                  rules="required"
                >
                  <b-form-input
                    id="father_profession"
                    v-model="dataStudent.father_profession"
                    placeholder="Karyawan Swasta"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Pekerjaan"
                label-for="mother_profession"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Pekerjaan"
                  rules="required"
                >
                  <b-form-input
                    id="mother_profession"
                    v-model="dataStudent.mother_profession"
                    placeholder="Karyawan Swasta"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Provinsi"
                rules="required"
              >
                <b-form-group
                  label="Provinsi"
                  label-for="father_province"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="father_province"
                    v-model="locations.father_province"
                    placeholder="Pilih Provinsi"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataProvinces"
                    label="name"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Provinsi"
                rules="required"
              >
                <b-form-group
                  label="Provinsi"
                  label-for="mother_province"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="mother_province"
                    v-model="locations.mother_province"
                    placeholder="Pilih Provinsi"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataProvinces"
                    label="name"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Kabupaten/Kota"
                rules="required"
              >
                <b-form-group
                  label="Kabupaten/Kota"
                  label-for="father_city"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="father_city"
                    v-model="locations.father_city"
                    placeholder="Pilih Kabupaten/Kota"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="filterCitiesFather"
                    label="name"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Kabupaten/Kota"
                rules="required"
              >
                <b-form-group
                  label="Kabupaten/Kota"
                  label-for="mother_city"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="mother_city"
                    v-model="locations.mother_city"
                    placeholder="Pilih Kabupaten/Kota"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="filterCitiesMother"
                    label="name"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Kecamatan"
                rules="required"
              >
                <b-form-group
                  label="Kecamatan"
                  label-for="father_distric"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="father_distric"
                    v-model="locations.father_distric"
                    placeholder="Pilih Kecamatan"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="filterDistricsFather"
                    label="name"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Kecamatan"
                rules="required"
              >
                <b-form-group
                  label="Kecamatan"
                  label-for="mother_distric"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="mother_distric"
                    v-model="locations.mother_distric"
                    placeholder="Pilih Kecamatan"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="filterDistricsMother"
                    label="name"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Kelurahan/Desa"
                rules="required"
              >
                <b-form-group
                  label="Kelurahan/Desa"
                  label-for="father_subdistric"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="father_subdistric"
                    v-model="locations.father_subdistric"
                    placeholder="Pilih Kelurahan/Desa"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="filterSubDistricsFather"
                    label="name"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Kelurahan/Desa"
                rules="required"
              >
                <b-form-group
                  label="Kelurahan/Desa"
                  label-for="mother_subdistric"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="mother_subdistric"
                    v-model="locations.mother_subdistric"
                    placeholder="Pilih Kelurahan/Desa"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="filterSubDistricsMother"
                    label="name"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="RW"
                label-for="father_rw"
              >
                <validation-provider
                  #default="{ errors }"
                  name="RW"
                  rules="required"
                >
                  <b-form-input
                    id="father_rw"
                    v-model="dataStudent.father_rw"
                    :state="errors.length > 0 ? false : null"
                    placeholder="2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="RW"
                label-for="mother_rw"
              >
                <validation-provider
                  #default="{ errors }"
                  name="RW"
                  rules="required"
                >
                  <b-form-input
                    id="mother_rw"
                    v-model="dataStudent.mother_rw"
                    :state="errors.length > 0 ? false : null"
                    placeholder="2"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="RT"
                label-for="father_rt"
              >
                <validation-provider
                  #default="{ errors }"
                  name="RT"
                  rules="required"
                >
                  <b-form-input
                    id="father_rt"
                    v-model="dataStudent.father_rt"
                    :state="errors.length > 0 ? false : null"
                    placeholder="1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="RT"
                label-for="mother_rt"
              >
                <validation-provider
                  #default="{ errors }"
                  name="RT"
                  rules="required"
                >
                  <b-form-input
                    id="mother_rt"
                    v-model="dataStudent.mother_rt"
                    :state="errors.length > 0 ? false : null"
                    placeholder="1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Alamat"
                label-for="father_address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Alamat"
                  rules="required"
                >
                  <b-form-input
                    id="father_address"
                    v-model="dataStudent.father_address"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Jl. Kaliurang Km. 7 No.B5"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Alamat"
                label-for="mother_address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Alamat"
                  rules="required"
                >
                  <b-form-input
                    id="mother_address"
                    v-model="dataStudent.mother_address"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Jl. Kaliurang Km. 7 No.B5"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Status dalam keluarga"
                rules="required"
              >
                <b-form-group
                  label="Status dalam keluarga"
                  label-for="father_parental_status"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="father_parental_status"
                    v-model="dataStudent.father_parental_status"
                    :reduce="(status) => status.value"
                    placeholder="Pilih Status dalam keluarga"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataParentStatus"
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Status dalam keluarga"
                rules="required"
              >
                <b-form-group
                  label="Status dalam keluarga"
                  label-for="mother_parental_status"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="mother_parental_status"
                    v-model="dataStudent.mother_parental_status"
                    :reduce="(status) => status.value"
                    placeholder="Pilih Status dalam keluarga"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataParentStatus"
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="No Handphone"
                label-for="father_handphone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="No Handphone"
                  rules="required"
                >
                  <b-form-input
                    id="father_handphone"
                    v-model="dataStudent.father_handphone"
                    :state="errors.length > 0 ? false : null"
                    placeholder="081123456XXX"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="No Handphone"
                label-for="mother_handphone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="No Handphone"
                  rules="required"
                >
                  <b-form-input
                    id="mother_handphone"
                    v-model="dataStudent.mother_handphone"
                    :state="errors.length > 0 ? false : null"
                    placeholder="081123456XXX"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Email"
                label-for="father_email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    id="father_email"
                    v-model="dataStudent.father_email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="jhondue@gmail.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Email"
                label-for="mother_email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input
                    id="mother_email"
                    v-model="dataStudent.mother_email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="jhondue@gmail.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="NIK"
                label-for="father_nik"
              >
                <validation-provider
                  #default="{ errors }"
                  name="NIK"
                  rules="required"
                >
                  <b-form-input
                    id="father_nik"
                    v-model="dataStudent.father_nik"
                    :state="errors.length > 0 ? false : null"
                    placeholder="2733498382432432"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="NIK"
                label-for="mother_nik"
              >
                <validation-provider
                  #default="{ errors }"
                  name="NIK"
                  rules="required"
                >
                  <b-form-input
                    id="mother_nik"
                    v-model="dataStudent.mother_nik"
                    :state="errors.length > 0 ? false : null"
                    placeholder="2733498382432432"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- address  -->
      <tab-content
        title="Pendidikan Sebelumnya"
        :before-change="validationFormAddress"
      >
        <validation-observer
          ref="addressRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Data Pendidikan Sebelumnya
              </h5>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Mutation"
                rules="required"
              >
                <b-form-group
                  label="Mutation"
                  label-for="mutation"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="mutation"
                    v-model="dataStudent.mutation"
                    :reduce="(mutation) => mutation.value"
                    placeholder="Pilih Mutation"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataMutations"
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Asal Sekolah"
                label-for="school_origin"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Asal Sekolah"
                  rules="required"
                >
                  <b-form-input
                    id="school_origin"
                    v-model="dataStudent.school_origin"
                    :state="errors.length > 0 ? false : null"
                    placeholder="SMK TUNAS HARAPAN"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Alamat Sekolah"
                label-for="school_address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Alamat Sekolah"
                  rules="required"
                >
                  <b-form-input
                    id="school_address"
                    v-model="dataStudent.school_address"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Jl. Pati-Tayu Km.04 No.78B"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Tahun Masuk"
                label-for="year_of_entry"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tahun Masuk"
                  rules="required"
                >
                  <b-form-input
                    id="year_of_entry"
                    v-model="dataStudent.year_of_entry"
                    :state="errors.length > 0 ? false : null"
                    placeholder="2015"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Tahun Kelulusan"
                label-for="graduation_year"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tahun Kelulusan"
                  rules="required"
                >
                  <b-form-input
                    id="graduation_year"
                    v-model="dataStudent.graduation_year"
                    :state="errors.length > 0 ? false : null"
                    placeholder="2018"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="No SKHUN"
                label-for="skhun_no"
              >
                <validation-provider
                  #default="{ errors }"
                  name="No SKHUN"
                  rules="required"
                >
                  <b-form-input
                    id="skhun_no"
                    v-model="dataStudent.skhun_no"
                    :state="errors.length > 0 ? false : null"
                    placeholder="73443439394"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="No Ijazah"
                label-for="ijazah_no"
              >
                <validation-provider
                  #default="{ errors }"
                  name="No Ijazah"
                  rules="required"
                >
                  <b-form-input
                    id="ijazah_no"
                    v-model="dataStudent.ijazah_no"
                    :state="errors.length > 0 ? false : null"
                    placeholder="73443439394"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Alasan Pindah"
                label-for="reason_to_move"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Alasan Pindah"
                  rules="required"
                >
                  <b-form-input
                    id="reason_to_move"
                    v-model="dataStudent.reason_to_move"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Mengikuti orang tua"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Diterima Di Kelas"
                rules="required"
              >
                <b-form-group
                  label="Diterima Di Kelas"
                  label-for="accepted_in_class"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="accepted_in_class"
                    v-model="dataStudent.accepted_in_class"
                    :reduce="(accepted_in_class) => accepted_in_class.value"
                    placeholder="Pilih Kelas"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dataClasss"
                    label="text"
                  />
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Tanggal Efektif Diterima"
                label-for="effective_date_received"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tanggal Efektif Diterima"
                  rules="required"
                >
                  <b-form-input
                    id="effective_date_received"
                    v-model="dataStudent.effective_date_received"
                    :state="errors.length > 0 ? false : null"
                    placeholder="2021-11-17"
                    type="date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- social link -->
      <tab-content
        title="Lampiran Dokumen"
        :before-change="validationFormSocial"
      >
        <validation-observer
          ref="socialRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h5 class="mb-0">
                Lampiran Dokumen
              </h5>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Fotokopi KK"
                label-for="fc_kk"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Fotokopi KK"
                  rules="required|size:500"
                >
                  <b-form-file
                    id="fc_kk"
                    v-model="dataStudent.fc_kk"
                    :state="errors.length > 0 ? false : null"
                    accept=".jpg, .png, .jpeg, .pdf"
                    placeholder="File format .jpg, .png, .jpeg, .pdf"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Fotokopi Akta Kelahiran"
                label-for="fc_akta"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Fotokopi Akta Kelahiran"
                  rules="required|size:500"
                >
                  <b-form-file
                    id="fc_akta"
                    v-model="dataStudent.fc_akta"
                    :state="errors.length > 0 ? false : null"
                    accept=".jpg, .png, .jpeg, .pdf"
                    placeholder="File format .jpg, .png, .jpeg, .pdf"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Fotokopi Ijazah"
                label-for="fc_ijazah"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Fotokopi Ijazah"
                  rules="required|size:500"
                >
                  <b-form-file
                    id="fc_ijazah"
                    v-model="dataStudent.fc_ijazah"
                    :state="errors.length > 0 ? false : null"
                    accept=".jpg, .png, .jpeg, .pdf"
                    placeholder="File format .jpg, .png, .jpeg, .pdf"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Foto 3 x 4 Berwarna"
                label-for="color_photo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Foto 3 x 4 Berwarna"
                  rules="required|size:500"
                >
                  <b-form-file
                    id="color_photo"
                    v-model="dataStudent.color_photo"
                    :state="errors.length > 0 ? false : null"
                    accept=".jpg, .png, .jpeg, .pdf"
                    placeholder="File format .jpg, .png, .jpeg, .pdf"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Foto 3 x 4 Hitam Putih"
                label-for="photo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Foto 3 x 4 Hitam Putih"
                  rules="required|size:500"
                >
                  <b-form-file
                    id="photo"
                    v-model="dataStudent.photo"
                    :state="errors.length > 0 ? false : null"
                    accept=".jpg, .png, .jpeg, .pdf"
                    placeholder="File format .jpg, .png, .jpeg, .pdf"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Dokumen Lainnya"
                label-for="other_document"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Dokumen Lainnya"
                  rules="required|size:500"
                >
                  <b-form-file
                    id="other_document"
                    v-model="dataStudent.other_document"
                    :state="errors.length > 0 ? false : null"
                    accept=".jpg, .png, .jpeg, .pdf"
                    placeholder="File format .jpg, .png, .jpeg, .pdf"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { required, email, size } from '@validations'
import dataCountries from '@/assets/json/countries.json'
import dataProvinces from '@/assets/json/provinces.json'
import dataCities from '@/assets/json/cities.json'
import dataDistrics from '@/assets/json/districts.json'
import dataSubDistrics from '@/assets/json/subdistricts.json'
// import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormFile,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      required,
      email,
      size,
      dataCountries,
      dataProvinces,
      dataCities,
      dataDistrics,
      dataSubDistrics,
      dataStudent: {
        nisn: '',
        name: '',
        birth_place: '',
        birth_date: '',
        gender: '',
        religion: '',
        nationality: '',
        child_number: '',
        number_of_sibling: '',
        relationship_in_family: '',
        every_day_language: '',
        address: '',
        distance: '',
        child_number_institution: '',
        reading_ability_quran: '',
        blood_type: '',
        illness_history: '',
        father_name: '',
        mother_name: '',
        father_birth_place: '',
        mother_birth_place: '',
        father_birth_date: '',
        mother_birth_date: '',
        father_religion: '',
        mother_religion: '',
        father_nationality: '',
        mother_nationality: '',
        father_education_level_id: '',
        mother_education_level_id: '',
        father_education_major: '',
        mother_education_major: '',
        father_salary: '',
        mother_salary: '',
        father_profession: '',
        mother_profession: '',
        father_rw: '',
        mother_rw: '',
        father_rt: '',
        mother_rt: '',
        father_address: '',
        mother_address: '',
        father_parental_status: '',
        mother_parental_status: '',
        father_handphone: '',
        mother_handphone: '',
        father_email: '',
        mother_email: '',
        father_nik: '',
        mother_nik: '',
        mutation: '',
        school_origin: '',
        school_address: '',
        year_of_entry: '',
        graduation_year: '',
        skhun_no: '',
        ijazah_no: '',
        reason_to_move: '',
        accepted_in_class: '',
        effective_date_received: '',
        fc_kk: null,
        fc_akta: null,
        fc_ijazah: null,
        color_photo: null,
        photo: null,
        other_document: null,
      },
      locations: {
        province: '',
        city: '',
        distric: '',
        subdistric: '',
        father_province: '',
        mother_province: '',
        father_city: '',
        mother_city: '',
        father_distric: '',
        mother_distric: '',
        father_subdistric: '',
        mother_subdistric: '',
      },
      // selectedContry: '',
      // selectedLanguage: '',
      // name: '',
      // emailValue: '',
      // PasswordValue: '',
      // passwordCon: '',
      // first_name: '',
      // last_name: '',
      // address: '',
      // landMark: '',
      // pincode: '',
      // twitterUrl: '',
      // facebookUrl: '',
      // googleUrl: '',
      // linkedinUrl: '',
      // city: '',
      // required,
      // email,
      // codeIcon,
      dataGender: [
        { value: 'M', text: 'Laki-laki' },
        { value: 'F', text: 'Perempuan' },
      ],
      dataReligion: [
        { value: 1, text: 'Islam' },
        { value: 2, text: 'Protestan' },
        { value: 3, text: 'Katolik' },
        { value: 4, text: 'Hindu' },
        { value: 5, text: 'Buddha' },
        { value: 6, text: 'Khonghucu' },
      ],
      dataReadingQuran: [
        { value: 'lancar', text: 'Lancar' },
        { value: 'belum_lancar', text: 'Belum Lancar' },
        { value: 'belum_bisa', text: 'Belum Bisa' },
      ],
      dataEducation: [{ value: 1, text: 'SD/MI' }],
      dataParentStatus: [
        { value: '1', text: 'Kandung' },
        { value: '2', text: 'Tiri' },
        { value: '3', text: 'Angkat' },
      ],
      dataMutations: [
        { value: 'Y', text: 'Ya' },
        { value: 'N', text: 'Tidak' },
      ],
      dataClasss: [{ value: '4nvoeygeoxw3zpra', text: 'VII' }],
    }
  },
  computed: {
    filterCities() {
      return this.dataCities.filter(
        item => item.parent === this.locations.province.wilayah_id,
      )
    },
    filterDistrics() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.city.wilayah_id,
      )
    },
    filterSubDistrics() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.distric.wilayah_id,
      )
    },
    filterCitiesFather() {
      return this.dataCities.filter(
        item => item.parent === this.locations.father_province.wilayah_id,
      )
    },
    filterCitiesMother() {
      return this.dataCities.filter(
        item => item.parent === this.locations.mother_province.wilayah_id,
      )
    },
    filterDistricsFather() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.father_city.wilayah_id,
      )
    },
    filterDistricsMother() {
      return this.dataDistrics.filter(
        item => item.parent === this.locations.mother_city.wilayah_id,
      )
    },
    filterSubDistricsFather() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.father_distric.wilayah_id,
      )
    },
    filterSubDistricsMother() {
      return this.dataSubDistrics.filter(
        item => item.parent === this.locations.mother_distric.wilayah_id,
      )
    },
  },
  methods: {
    async formSubmitted() {
      try {
        const dataFormStudent = new FormData()
        Object.keys(this.dataStudent).forEach(key => {
          dataFormStudent.append(key, this.dataStudent[key])
        })
        dataFormStudent.append('province', this.locations.province.name)
        dataFormStudent.append('city', this.locations.city.name)
        dataFormStudent.append('district', this.locations.distric.name)
        dataFormStudent.append('subdistrict', this.locations.subdistric.name)
        dataFormStudent.append(
          'father_province',
          this.locations.father_province.name,
        )
        dataFormStudent.append(
          'mother_province',
          this.locations.mother_province.name,
        )
        dataFormStudent.append('father_city', this.locations.father_city.name)
        dataFormStudent.append('mother_city', this.locations.mother_city.name)
        dataFormStudent.append(
          'father_district',
          this.locations.father_distric.name,
        )
        dataFormStudent.append(
          'mother_district',
          this.locations.mother_distric.name,
        )
        dataFormStudent.append(
          'father_subdistrict',
          this.locations.father_subdistric.name,
        )
        dataFormStudent.append(
          'mother_subdistrict',
          this.locations.mother_subdistric.name,
        )
        await this.$http.post('/students', dataFormStudent, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Siswa Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'data-siswa' })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Tambah Data Siswa Tidak Berhasil',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
